<template>
	<div>
		<div>
			<div>
				<!-- header  点击 -->
				<headers @tab1="tabs1" @tab2="tabs2" @tab3="tabs3" :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div class="wrap user_set bgw">
					<div v-if="navTab < 3" class="nav">
						<div @click="navTab = 0" :class="{navActive: navTab == 0}">
							个人资料
							<i></i>
						</div>
						<div @click=" navTab= 1" :class="{navActive: navTab == 1}">
							修改密码
							<i></i>
						</div>
						<div @click=" navTab= 2" :class="{navActive: navTab == 2}">
							支付密码
							<i></i>
						</div>
					</div>
					<div v-if="navTab == 3" class="nav">
						<div>修改手机号</div>
					</div>
					<!-- 修改 个人信息  -->
					<div v-if="navTab == 0" class="content">
						<div class="user_name">
							<span>昵称:</span>
							<input maxlength="12" type="text" v-model="name" placeholder="用户昵称" />
						</div>
						<div class="user_img">
							<span>头像</span>
							<div class="upload_img">
								<div class="upload_btn" v-if="avatar==''">
									<p class="el-icon-camera icon"></p>
									<p>点击上传处方单图片</p>
								</div>
								<div class="upload_btn" v-else>
									<img :src="avatar">
								</div>
								<input ref="upload_img" @change="OpenUpload" type="file" id="test-image-file" name="test" accept="image/gif, image/jpeg, image/png, image/jpg">
							</div>
						</div>
						<div class="user_birthday">
							<span>出生日期</span>
							<el-date-picker v-model="birthday" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
						</div>
						<div class="user_sex">
							<span>性别</span>
							<el-radio v-model="sex" :label="1">男性</el-radio>
							<el-radio v-model="sex" :label="2">女性</el-radio>
						</div>
						<button @click="UserDataChecking()">保存</button>
					</div>

					<!-- 修改 密码 -->
					<div v-show="navTab == 1" class="content user_set_phone">
						<div>
							<span>手机号</span>
							<input v-model="phone" type="text " maxlength="11" />
						</div>
						<div class="checking">
							<span>验证码</span>
							<input v-model="phoneCode" type="text " maxlength="6" />
							<p @click="phoneChecking()" v-if="time == 60 ">获取验证</p>
							<p v-if="time != 60 ">{{time}}</p>
						</div>
						<div>
							<span>输入新密码</span>
							<input v-model="password1" maxlength="12" type="password " />
						</div>
						<div>
							<span>再次输入新密码</span>
							<input v-model="password2" maxlength="12" type="password " />
						</div>
						<button @click="UserPasswordChecking()">保存</button>
					</div>
					<!-- 修改支付密码 -->
					<div v-show="navTab == 2" class="content user_set_phone">
						<div>
							<span>手机号</span>
							<input v-model="payphone" type="text " maxlength="11" />
						</div>
						<div class="checking">
							<span>验证码</span>
							<input v-model="paycode" type="text " maxlength="6" />
							<p @click="phoneChecking('pay')" v-if="time1 == 60 ">获取验证</p>
							<p v-if="time1 != 60 ">{{time1}}</p>
						</div>
						<div>
							<span>输入新密码</span>
							<input v-model="payword1" maxlength="12" type="password " />
						</div>
						<div>
							<span>再次输入新密码</span>
							<input v-model="payword2" maxlength="12" type="password " />
						</div>
						<button @click="UserPasswordChecking('pay')">保存</button>
					</div>
					<!-- 修改 手机号 -->
					<div v-show="navTab == 3" class="content user_set_phone">
						<div>
							<span>新手机号</span>
							<input v-model="phone2" type="text " maxlength="11" />
						</div>
						<div class="checking">
							<span>验证码</span>
							<input v-model="phoneCode2" type="text " maxlength="6" />
							<p @click="phoneChecking('upswd')" v-if="time2 == 60 ">获取验证</p>
							<p v-if="time2 != 60 ">{{time2}}</p>
						</div>
						<div>
							<span>登录密码</span>
							<input v-model="password" maxlength="12" type="password" />
						</div>
						<button @click="UserPhoneChecking('upswd')">绑定</button>
					</div>
				</div>
			</div>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>

<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部

	import {
		usergetinfo
	} from "@/request/user";
	import {
		sendSms
	} from "@/request/public"; //手机验证
	import {
		updateinfo
	} from "@/request/user"; // 修改个人信息
	import {
		modifypassword
	} from "@/request/user"; //修改密码
	import {
		modifyMobile
	} from "@/request/user"; // 修改 手机号

	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				userList: [], // 个人信息
				navTab: 0,
				birthday: "", // 出生日期
				sex: -1, //性别
				checking1: "", //验证
				time: 60, //倒计时
				time1: 60,
				time2: 60,
				phone: "", //手机号
				phoneCode: "", //手机号验证码
				password1: "", //新密码
				password2: "", // 再次 输入 新密码
				password3: "", //登录密码
				phone2: "",
				phoneCode2: "",
				password: "",
				payphone: "",
				paycode: "",
				payword1: "",
				payword2: "",
				name: "", // 个人信息  名字
				avatar: "", // 头像
				imageUrl: "",
				upload_imgurl: "",
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			this.init();
			this.navTab = this.$route.query.state;
		},
		methods: {
			// 选择图片
			OpenUpload(e) {
				console.log(e);
				let files = e.target.files[0];
				this.formData = new FormData();
				this.formData.append("file", files);
				this.formData.append("token", this.$token);
				let url = "";
				let reader = new FileReader();
				reader.readAsDataURL(files)
				let that = this;
				reader.onload = function() {
					url = this.result.substring(this.result.indexOf(",") + 1);
					that.upload_imgurl = "data:image/png;base64," + url;
					console.log(that.upload_imgurl);
					that.upload_file();
				}
			},
			// 上传
			upload_file() {
				this.$axios.post("/api/file/upload", this.formData).then(res => {
					if (res.data.code == 1) {
						this.avatar=res.data.data.file.url;
					}
				});

			},
			// 清除
			clearimg() {
				// 清除图片
				console.log(111);
				this.upload_imgurl = "";
			},
			tabs1() {
				this.navTab = 0;
			},
			tabs2() {
				this.navTab = 1;
			},
			tabs3() {
				this.navTab = 3;
			},
			init() {
				//   个人信息
				usergetinfo({
					token: this.$tokens
				}).then(res => {
					console.log(res);
					this.userList = res.data;
					this.sex = this.userList.sex;
					this.name = this.userList.user_nickname;
					this.birthday = this.userList.birthday;
					this.imageUrl = this.userList.avatar;
					this.avatar = this.userList.avatar;
				});
			},
			UserPhoneChecking(type) {
				if (this.phone2 == "") {
					this.$message.error("手机号不能为空");
					return false;
				} else if (!/^1[3456789]\d{9}$/.test(this.phone2)) {
					this.$message.error("手机号格式不正确");
					return false;
				} else if (this.phoneCode2 == "") {
					this.$message.error("验证码不能为空");
					return false;
				} else {
					this.UserPhoneSend();
				}
			},
			UserPhoneSend() {
				modifyMobile({
					token: this.$tokens,
					new_mobile: this.phone2,
					code: this.phoneCode2,
					user_pass: this.password
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "修改成功",
							type: "success"
						});
						this.$router.replace({
							path: "/user/index"
						});
					}
				});
			},
			UserPasswordChecking(is_pay) {
				var pw1;
				var pw2;
				var code;
				var type;
				if (is_pay == "pay") {
					pw1 = this.payword1;
					pw2 = this.payword2;
					code = this.paycode;
					type = 1;
					if (!this.pswdPhoneChecking(this.payphone, this.paycode)) return false;
				} else {
					pw1 = this.password1;
					pw2 = this.password2;
					code = this.phoneCode;
					type = 0;
					if (!this.pswdPhoneChecking(this.phone, this.phoneCode)) return false;
				}
				if (pw1 == "") {
					this.$message.error("密码 不能 为空");
					return;
				} else if (pw1.length < 5) {
					this.$message.error("密码 不能 低于 6位");
					return;
				} else if (pw2 != pw2) {
					this.$message.error("密码 不一致");
					return;
				} else {
					this.UserPasswordSend(pw1, pw2, code, type);
				}
			},
			pswdPhoneChecking(phone, code) {
				if (phone == "") {
					this.$message.error("手机号不能为空");
					return false;
				} else if (!/^1[3456789]\d{9}$/.test(phone)) {
					this.$message.error("手机号格式不正确");
					return false;
				} else if (code == "") {
					this.$message.error("验证码不能为空");
					return false;
				} else {
					return true;
				}
			},
			UserPasswordSend(pw1, pw2, code, type) {
				modifypassword({
					token: this.$tokens,
					password: this.$md5(pw1),
					repassword:this.$md5(pw2),
					code,
					type
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "修改成功",
							type: "success"
						});
						this.$router.replace({
							path: "/user/index"
						});
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			UserDataChecking() {
				if (this.name == "") {
					this.$message.error("昵称 不能 为空");
					return;
				} else if (this.avatar == "") {
					this.$message.error("头像 不能 为空");
				} else if (this.birthday == "") {
					this.$message.error("出生日期 不能 为空");
				} else if (this.sex == "") {
					this.$message.error("性别 不能 为空");
				} else {
					this.UserDataSend();
				}
			},
			UserDataSend() {
				updateinfo({
					token: this.$tokens,
					user_nickname: this.name,
					avatar: this.avatar,
					birthday: this.birthday,
					sex: this.sex
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "修改成功",
							type: "success"
						});
						this.$router.replace({
							path: "/user/index"
						});
					}
				});
			},
			//  个人 头像
			UserImgSuccess(response, file) {
				let imgurl = response.data.file.url;
				file.url = imgurl;
				this.avatar = response.data.file.url;
			},
			// 个人头像 删除
			UserImgtRemove() {
				this.avatar = "";
			},
			// 手机号验证
			phoneChecking(type) {
				var phone;
				if (type == "pay") {
					phone = this.payphone;
				} else if (type == "upswd") {
					phone = this.phone2;
				} else {
					phone = this.phone;
				}
				if (phone == "") {
					this.$message.error("手机号不能为空");
					return false;
				} else if (!/^1[3456789]\d{9}$/.test(phone)) {
					this.$message.error("手机号格式不正确");
					return false;
				} else if (this.time == 60) {
					this.times(type);
					console.log();
					sendSms({
						account: phone
					}).then(res => {
						if (res.code == 1) {
							this.checking1 = 2;
							this.$message({
								message: "发送成功",
								type: "success"
							});
						}
					});
				}
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === "image/jpeg";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			times(type) {
				var Athis = this;
				var setTime = setTimeout(function() {
					if (type == "pay") {
						if (Athis.time1 == 0) {
							clearTimeout(setTime);
							Athis.time1 = 60;
						} else {
							Athis.time1--;
							Athis.times(type);
						}
					} else if (type == "upswd") {
						if (Athis.time2 == 0) {
							clearTimeout(setTime);
							Athis.time2 = 60;
						} else {
							Athis.time2--;
							Athis.times(type);
						}
					} else {
						if (Athis.time == 0) {
							clearTimeout(setTime);
							Athis.time = 60;
						} else {
							Athis.time--;
							Athis.times();
						}
					}
				}, 1000);
			}
		}
	};
</script>

<style lang="less" scoped>
	.upload_img {
		border: 1px solid #ccc;
		height: 200px;
		position: relative;

		input {
			width: 100%;
			height: 100%;
			background-color: red;
			position: absolute;
			top:0;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
		}

		.upload_btn {
			width: 150px;
			height: 150px;
			margin: 0 auto;
			background-color: #F0F0F0;
			text-align: center;
			border-radius: 10px;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}

			.icon {
				margin-top: 30px;
				font-size: 50px;
				color: #CCCCCC;
				margin-bottom: 10PX;
			}

			P {
				font-size: 14PX;
				color: #CCCCCC
			}
		}
	}

	.indexUse_content {
		background: #f5f5f5;
		padding-top: 24px;
		padding-bottom: 24px;
	}

	.user_set {
		min-height: 600px;

		.checking {
			input {
				width: 237px !important;
			}

			p {
				cursor: Default;
				margin-left: 16px;
				display: inline-block;
				width: 160px;
				height: 46px;
				color: #808080;
				line-height: 46px;
				font-size: 16px;
				text-align: center;
				background: rgba(230, 230, 230, 1);
			}
		}
	}

	// 修改密码
	.user_set_phone {
		span {
			width: 130px !important;
		}

		input {
			width: 380px;
			height: 46px;
			text-indent: 10px;
			border: 1px solid #e6e6e6 !important;
		}
	}

	// 个人资料
	.user_set {
		padding-bottom: 40px;

		button {
			width: 130px;
			height: 46px;
			font-size: 18px;
		}
	}

	.user_set .user_sex {
		.el-radio__inner {
			width: 16px;
			height: 16px;
		}
	}

	.user_set .user_birthday {
		.el-input__inner {
			width: 220px;
			height: 46px;
			line-height: 46px;
			border: 1px solid #e6e6e6 !important;
			font-size: 16px;
		}

		.el-input__icon {
			line-height: 48px;
		}
	}

	.user_set .user_img {
		height: 150px !important;

		span {
			position: absolute;
			left: 0;
			top: 0;
		}

		>div {
			// // margin-left: 90px;
			// padding-left: 90px;
			overflow: hidden;
			height: 150px;
			position: absolute;
			left: 90px;
			width: 150px;
		}

		p {
			position: absolute;
			left: 90px;
			width: 186px;
			text-align: center;
			background: rgba(0, 0, 0, 0.6);
			bottom: 0;
			color: white;
		}
	}

	.user_set .user_name {
		input {
			width: 380px;
			height: 46px;
			text-indent: 10px;
			border: 1px solid #e6e6e6 !important;
		}
	}

	.user_set .content {
		padding-top: 46px;
		padding-left: 72px;
		color: #4d4d4d;
		font-size: 16px;

		>div {
			height: 46px;
			position: relative;
			margin-bottom: 36px;
		}

		>div>span {
			display: inline-block;
			width: 90px;
		}
	}

	.user_set .user_num {
		line-height: 46px;
	}

	.user_set .nav {
		width: 100%;
		height: 68px;
		border-bottom: 1px solid #e6e6e6;
		font-size: 18px;
		padding-left: 20px;
		display: flex;

		>div {
			line-height: 68px;
			padding-left: 16px;
			padding-right: 16px;
			position: relative;
			cursor: Pointer;
		}
	}

	.user_set .navActive {
		color: #4eeec8;

		i {
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 100%;
			height: 1px;
			border-bottom: 2px solid #4eeec8;
		}
	}

	.user_set {
		border: 1px solid #e6e6e6;
	}
</style>
